import React from "react";

import styled from "styled-components";
import { createGlobalStyle } from "styled-components";

import MemoLogo from "./Logo";
import { normalize } from "styled-normalize";

import { motion } from "framer-motion";

/* icons */
import { BiPhoneCall } from "react-icons/bi";
import { BiMailSend } from "react-icons/bi";

/* background */
import back from "./images/back.png";

const GlobalStyle = createGlobalStyle`

@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
   
${normalize}




  body {
   box-sizing: border-box;
   margin: 0;
   padding: 0;

   font-family: 'Oswald', sans-serif;

   
  }

  a{
      text-decoration: none;
      font-family: "Montserrat", sans-serif;
      font-size: 2vw;
      color: black;
      }
`;

/* styled components START ----------------------------------------------------------------------------------> */

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;

  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  overflow: hidden;
`;

const Container = styled.div`
  width: 100%;
  height: 80vh;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;

  .logoContainer {
    width: 20vw;
    height: fit-content;

    @media (max-width: 768px) {
      width: 40vw;
    }
  }

  .iconsContainer {
    display: flex;
    justify-content: center;

    h2 {
      font-family: "Montserrat", sans-serif;
      font-size: 1.5vw;

      @media (max-width: 768px) {
        font-size: 3vw;
      }

      @media (max-width: 400px) {
        font-size: 3.5vw;
      }

      .icon {
        color: red;
      }
    }
  }
`;

const Button = styled.a`
  padding: 10px;
  margin: 5px;
  /* background-color: gray; */
  border-radius: 2em;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 10vw;
  color: black;

  font-weight: 300;

  text-align: center;
  font-family: "Oswald", sans-serif;
  margin: 0;
  padding: 0;

  @media (max-width: 768px) {
    font-size: 15vw;
  }
`;

const Paragraph = styled.p`
  text-align: center;
  margin: 0;
  padding: 10px;

  font-size: 2vw;
  font-family: "Montserrat", sans-serif;

  @media (max-width: 768px) {
    font-size: 4vw;
  }
`;

const Back = styled.img`
  position: absolute;
  top: 0;

  width: auto;
  height: 100%;

  content: url(${back});

  z-index: -10;

  @media (max-width: 768px) {
  }
`;

const Location = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
`;

/* styled components END ----------------------------------------------------------------------------------> */

/* animation START -------------------------------------------------------------------------------------------------- */

const main = {
  hidden: { rotate: 360 },
  visible: {
    rotate: 0,

    transition: {
      /*    opacity: { duration: 1 },
      rotate: { duration: 5 }, */
      duration: 70,
      repeat: Infinity,
      repeatType: "loop",
    },
  },
};

/* animation END-------------------------------------------------------------------------------------------------- */

export const Page = () => {
  return (
    <>
      <Wrapper>
        <Back
          as={motion.img}
          initial="hidden"
          animate={"visible"}
          variants={main}
          style={{ scale: 2 }}
        ></Back>

        <Container>
          <div className="logoContainer">
            <MemoLogo></MemoLogo>
          </div>

          <div>
            <Title className="font-face-gm">COMING SOON</Title>
            <Paragraph>Our Website is under construction.</Paragraph>
          </div>

          <div>
            <div className="iconsContainer">
              <Button href="tel: +41 33 511 23 45">
                <h2>+41 33 511 23 45</h2>
              </Button>

              <Button href="mailto: info@tcm.energy" target={"_blank"}>
                <h2>info@tcm.energy</h2>
              </Button>
            </div>

            <div className="iconsContainer">
              <Location>
                <h2>Bahnhofstrasse 19</h2>
                <h2>CH-3700 Spiez BE</h2>
              </Location>

              <Location>
                <h2>Frutigenstrasse 56</h2>
                <h2>CH-3604 Thun BE</h2>
              </Location>
            </div>
          </div>
        </Container>
        <GlobalStyle />
      </Wrapper>
    </>
  );
};
